@import url(https://fonts.googleapis.com/css?family=Righteous&display=swap&text=Glyfty);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main.container {
    margin-top: 1em;
}

.site-header {
    font-family: 'Righteous', sans-serif;
    font-size: 1.5em;
}

.glyfty-text {
    font-family: 'Righteous', sans-serif;
}

.menubar-lined {
    border-top: 1px solid grey;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #dde5ff;
    font-size: 1.1em;
}

.link {
    cursor: pointer;
}

.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    min-height: 100px;
    border: 1px dashed #8aaaff;
    border-radius: 20px;
    text-align: center;
}

.dropzone div {
    padding-top: 8px;
    padding-bottom: 8px;
}

.auth-menu {
    padding-left: 15px !important;
    padding-right: 15px !important;
    background: rgba(62, 166, 255, 0.29) !important;
}

.auth-menu i {
    margin-left: -3px !important;
    margin-right: -3px !important;
}

.auth-menu-open .text {
    padding-right: 16px;
}

.new-voucher svg {
    display: block;
    margin: 3em auto 3em auto;
}

.new-voucher .button {
    display: block !important;
    margin: auto !important;
}

.capability {
    margin-top: 4px;
    margin-bottom: 4px;
}

.capability-list {
    margin-bottom: 4px;
}

.logo {
    width: 100px;
    height: 25px;
    margin-right: 12px;
}

.logo-tiny {
    width: 16px;
    height: 16px;
    margin: 0 4px 0 8px;
    vertical-align: middle;
}

.voucher {
    text-align: center;
}

.voucher.disabled {
    padding-top: 30px !important;
}

.voucher-title {
    display: inline-block;
    margin-top: 14px !important;
    margin-bottom: 10px !important;
}

.voucher-description {
    color: #575757;
    font-size: 16px;
}

.voucher-price {
    margin-bottom: 10px !important;
}

.voucher-labels {
    margin-top: 12px;
}

.voucher-labels > div + div {
    margin-top: 12px;
}

.copy-purchase-link {
    border-bottom: 1px solid #00000024 !important;
}

.external.icon {
    cursor: pointer !important;
}

.voucher-purchase-email input {
    max-width: 500px !important;
    min-width: 300px !important;
}

.voucher-purchase-email div {
    display: block !important;
}

.powered-by {
    color: #909090;
    margin-top: 40px;
}

.danger-zone {
    box-shadow: 0 0 8px 0 rgba(255, 0, 8, 0.31), 0 2px 10px 0 rgba(255, 0, 8, 0.4) !important;
    border: 1px solid rgba(255, 0, 0, 0.63) !important;
}

.danger-zone .label {
    background: rgba(255, 0, 0, 0.74) !important;
}

.SocialMediaShareButton {
    display: inline-block;
}

.purchase-success-message {
    font-size: 20px;
    color: green;
}

.header.invited-user {
    color: #d68200 !important;
    font-style: italic;
}

.userorg-details .label {
    overflow: hidden;
}

.button {
    padding: 8px 12px 8px 12px !important;
}

.button.red {
    background: #cf646c !important;
}

.button.green {
    background: #64ae67 !important;
}

.dev-banner {
    font-size: 1.5em;
    background-image: -webkit-linear-gradient(315deg, #cc8a8a 5%, #ff1f1f 5%, #ff1f1f 50%, #cc8a8a 50%, #cc8a8a 55%, #ff1f1f 55%, #ff1f1f 100%);
    background-image: linear-gradient(135deg, #cc8a8a 5%, #ff1f1f 5%, #ff1f1f 50%, #cc8a8a 50%, #cc8a8a 55%, #ff1f1f 55%, #ff1f1f 100%);
    background-size: 14px 14px;
    color: white;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.test-banner {
    font-size: 1.1em;
    font-weight: bold;
    background-image: -webkit-linear-gradient(315deg, #5ba36a 5%, #7dba80 5%, #7dba80 50%, #5ba36a 50%, #5ba36a 55%, #7dba80 55%, #7dba80 100%);
    background-image: linear-gradient(135deg, #5ba36a 5%, #7dba80 5%, #7dba80 50%, #5ba36a 50%, #5ba36a 55%, #7dba80 55%, #7dba80 100%);
    background-size: 14px 14px;
    color: #000000;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
}

.beta-banner {
    font-size: 1.1em;
    font-weight: bold;
    background: #8aa6ba;
    background-size: 14px 14px;
    color: #000000;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
}

.beta-banner a {
    color: #030306;
}

.test-banner a {
    color: #5d5d5d;
}

.card > .button {
    /*background: #f3f3f3 !important;*/
}

.button + a.button.btn-following {
    /*border-top: none !important;*/
}

body, #root {
    min-height: 100%;
}

#root {
    position: relative;
    min-height: 100%
}

#root::after {
    content: '';
    display: block;
    height: 75px;
}

body {
    min-height: 100%;
    position: relative;
}

.footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    /*background: green;*/
    width: 100%;
    height: 30px;
}

/*.footer {*/
/*    margin-top: 60px;*/
/*    text-align: center;*/

/*    clear: both;*/
/*    position: relative;*/
/*    height: 200px;*/
/*    margin-top: -200px;*/
/*}*/

.footer a {
    /*display: inline-block;*/
    padding: 4px;

}


.howitworks {
    max-width: 500px;
    width: 100%;
}

.pricing-header {
    /*margin: -15px !important;*/
    background: rgba(110, 179, 172, 0.73) !important;
    min-height: 130px;
    border-top: 1px solid #a8a8a8 !important;
}

.pricing-header .header {
    font-size: 2em !important;
    margin-top: 30px !important;
}

.pricing-detail.first {
    margin-top: 30px !important;
}

.pricing-detail {
    margin-top: 10px !important;
    padding-bottom: 20px !important;
}

.pricing-detail .content {
    font-size: 1.2em !important;
}

.pricing-detail .sub.header {
    padding-top: 10px !important;
    font-size: 1em !important;
}

.pricing-prices .header {
    text-align: center;
}

.pricing-prices .statistic {
    width: 100% !important;
}

.org-logo {
    width: 220px;
    margin-bottom: 12px;
}

.stripe-logo {
    width: 150px !important;
    float: right;
}

.user-id {
    float: right;
    color: grey;
    font-size: 0.9em;
}

.org-logo img {
    min-height: 230px;
}

.org-logo img:before {
    content: " ";
    display: block;

    position: absolute;
    top: 0px;
    left: -10px;
    height: calc(100%);
    width: 240px;
    background-color: rgb(252, 250, 255);
    border: 2px solid rgb(200, 200, 200);
    border-radius: 15px;
}

.org-logo img:after {
    content: "No logo yet.\AUpload in Settings";
    white-space: pre;
    display: block;
    font-size: 24px;
    font-weight: bold;
    color: rgb(93, 93, 93);

    position: absolute;
    top: 15px;
    padding-top: 60px;
    line-height: 1.5em;
    left: 0;
    width: 100%;
    text-align: center;
}

.menubar-email.disabled.item {
    margin-top: -18px !important;
}

.inline-error {
    display: inline-block;
    margin-left: 10px;
    color: red;
}

.org-disabled-warning {
    background: #ffcf87;
    font-size: 16px;
    padding: 8px;
    margin-top: -6px;
    margin-bottom: 12px;
    text-align: center;
}

.hamburger {
    float: right;
}

.ui.dropdown.hamburger .menu {
    left: -120px !important;
}

.ui.dropdown.purchase.hamburger .menu {
    left: -140px !important;
}

.ui.dropdown.org.hamburger .menu {
    left: -90px !important;
}

.ui.dropdown.purchase.hamburger .menu {
    top: calc(100% - 4px) !important;
}

.voucher.hamburger {
    margin-top: -6px;
    margin-right: -10px;
}

.hamburger > i {
    color: #717171;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.purchase.hamburger > i {
    padding-top: 6px !important;
}

.purchase.hamburger a {
    color: inherit;
}

.purchase.hamburger a:hover {
    color: inherit;
}

.purchase-date {
    font-size: 12px;
    color: grey;
}

.purchase-incomplete {
    float: right;
    display: inline-block;
    margin-right: 12px !important;
    margin-top: -15px !important;
}

.purchase-redeem-summary {
    float: right;
    display: inline-block;
    margin-right: 12px;
    margin-top: -10px;
}

.purchase-redeem-uses {
    padding-top: 8px;
    text-align: center;
}

.ui.basic.button.purchase-email {
    font-size: larger !important;
    color: #33527c !important;
    box-shadow: 0 0 !important;
    padding: 0 !important;
}

.glyfty-icon {
    width: 16.52px;
    height: 14px;
    display: inline-block;
    margin-right: 11px;
}

.colour-picker {
    cursor: pointer !important;
}

.colour-picker > input {
    width: 50px !important;
    opacity: 1 !important;
}

.colour-picker input {
    font-size: 14px !important;
}

.inline-edit {
    border: 1px solid #cccccc !important;
    margin: -1px;
}

.purchase-greeting textarea {
    max-width: 400px;
}

.greeting-length {
    margin-top: -15px;
}

.greeting-length-exceeded {
    color: red;
}

.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    /*
    padding-left: 8px !important;
    padding-right: 8px !important;
    */
    margin: 0;
    display: inline-block !important;
    color: rgb(65, 131, 196) !important;
}

